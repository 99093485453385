<style >
	.iconfont.iconfont-bg {
		font-size: 16px;
		font-weight: 500;
		/* color: rgba(255, 255, 255, 0.5); */
		/* float: right;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									transform: rotate(30deg);
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									position: relative;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									right: -20px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									bottom: -10px; */
	}
	.iconfont-bg {
		font-size: 16px;
		font-weight: 500;
		/* color: rgba(255, 255, 255, 0.5); */
		/* float: right;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									transform: rotate(30deg);
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									position: relative;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									right: -20px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									bottom: -10px; */
	}
	.scard {
		background: url(../../assets/border1.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard2 {
		background: url(../../assets/border2.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard3 {
		background: url(../../assets/border3.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 10px;
	}
	.scard5 {
		background: transparent;
		/* background: url(../../assets/border5.png) no-repeat center center; */
		/* background-size: 100% 100%; */
		/* background: transparent; */
		padding: 10px;
	}
	.scard5 .el-card__body {
		padding: 0;
	}
	.scard3 .el-table th,
	.scard3 .el-table tr,
	.scard3 td {
		background: transparent;
		border: 0;
		color: #fff;
	}
	.scard3 .el-table--enable-row-hover .el-table__body tr:hover > td {
		background-color: transparent !important;
	}
	.scard3 .el-table::before {
		background: transparent;
	}
	.snumber {
		background: url(../../assets/num2.png) no-repeat center center;
		background-size: 100% auto;
		height: 74px;
		padding-top: 40px;
	}
	.co2 .el-tabs__header {
		width: 110px;
		margin-left: 30px;
		margin-bottom: 0;
	}
	.co2 .el-tabs__item {
		color: #fff;
	}
	.co2 .el-tabs__item.is-active {
		color: #2c6dd2;
	}
</style>
<template>

	<div class="co2" style="background:#020435;width:100%;min-height:100%">
		<el-radio-group v-model="tab" style="margin-left:20px" size="mini">
			<el-radio-button label="1">综合能耗看板</el-radio-button>
			<el-radio-button label="2">工序能耗看板</el-radio-button>
		</el-radio-group>
		<el-container v-if="tab==1">
			<el-aside width="420px" style="padding:15px;">
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 氧化铝综合能耗看板 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{padding:0}" style="border:0;">
					<mechart id="echart0" title="" subtitle="" :style="'height:220px'" :options="echart0_opts" />
				</el-card>
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 氧化铝工艺能耗看板 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{padding:0}" style="border:0;">
					<mechart id="echart1" title="" subtitle="" :style="'height:220px'" :options="echart1_opts" />
				</el-card>
			</el-aside>
			<el-main>
				<div style="text-align:center">
					<el-row>
						<el-col :span="3" style="padding-top:14px">
							<img src="../../assets/co2/icon.png" width="40px" style="marign:12px auto">
						</el-col>
						<el-col :span="19">
							<el-row style="margin-top:10px">
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年烧结法能耗(t标煤)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:24px;">158468.35 </h1>
								</el-col>
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年拜耳法能耗(t标煤)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:24px;">372572.89 </h1>
								</el-col>
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">能源生产(t标煤)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:24px;">146662.55 </h1>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
					<el-row style="margin-top:150px">
						<el-col :span="12">
							<h4 style="color:#ffffffaa;font-size:12px;text-align:center;margin:0;margin-bottom:5px;">全年氧化铝综合能耗(kg标煤/t-AO)</h4>
							<h1 style="color:#fff;text-align:center;margin:0;font-size:28px;">312.19 </h1>
						</el-col>
						<el-col :span="12">
							<h4 style="color:#ffffffaa;font-size:12px;text-align:center;margin:0;margin-bottom:5px;">全年氧化铝工艺能耗(kg标煤/t-AO)</h4>
							<h1 style="color:#fff;text-align:center;margin:0;font-size:28px;">304.67 </h1>
						</el-col>
					</el-row>
					<img src="../../assets/co2/co2.png" style="width: 600px;margin: -150px auto 0px auto;" />
				</div>
			</el-main>
			<el-aside width="420px" style="padding:15px">
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 能源消耗量看板 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
					<mechart id="echart2" title="" subtitle="" :style="'height:220px'" :options="echart2_opts" />
				</el-card>
			</el-aside>
		</el-container>
		<el-container v-else>
			<el-main>
				<!-- 烧结法工序 -->
				<el-row style="width:600px">
					<el-col :span="1">
						<img src="../../assets/co2/icon.png" width="20px" style="marign:0px auto">
					</el-col>
					<el-col :span="23">
						<h4 style="color:#fff;text-align:left;margin:0">烧结法工序</h4>
					</el-col>
				</el-row>
				<el-row :gutter="16">
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">电力能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<div style="margin-bottom:-50px">
							<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(kWh/t-AO)</h4>
							<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">734.77 </h1>
						</div>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<mechart id="echart11" title="" subtitle="" :style="'height:160px'" :options="echart11_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">蒸汽能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(t/t-AO)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">3.86 </h1>
							</div>
							<mechart id="echart12" title="" subtitle="" :style="'height:160px'" :options="echart12_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">新水能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(m^3/t-AO)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">3.47 </h1>
							</div>
							<mechart id="echart13" title="" subtitle="" :style="'height:160px'" :options="echart13_opts" />
						</el-card>
					</el-col>
				</el-row>
				<!-- 拜耳法工序 -->
				<el-row style="width:600px">
					<el-col :span="1">
						<img src="../../assets/co2/icon.png" width="20px" style="marign:0px auto">
					</el-col>
					<el-col :span="23">
						<h4 style="color:#fff;text-align:left;margin:0">拜耳法工序</h4>
					</el-col>
				</el-row>
				<el-row :gutter="16">
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">电力能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<div style="margin-bottom:-50px">
							<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(kWh/t-AO)</h4>
							<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">218.06 </h1>
						</div>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<mechart id="echart21" title="" subtitle="" :style="'height:160px'" :options="echart21_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">蒸汽能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(t/t-AO)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">2.07 </h1>
							</div>
							<mechart id="echart22" title="" subtitle="" :style="'height:160px'" :options="echart22_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">新水能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(m^3/t-AO)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">0.8 </h1>
							</div>
							<mechart id="echart23" title="" subtitle="" :style="'height:160px'" :options="echart23_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">天燃气能耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(m^3/t-AO)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">83.15 </h1>
							</div>
							<mechart id="echart24" title="" subtitle="" :style="'height:160px'" :options="echart24_opts" />
						</el-card>
					</el-col>
				</el-row>
				<!-- 能源生产工序 -->
				<el-row style="width:600px">
					<el-col :span="1">
						<img src="../../assets/co2/icon.png" width="20px" style="marign:0px auto">
					</el-col>
					<el-col :span="23">
						<h4 style="color:#fff;text-align:left;margin:0">能源生产工序</h4>
					</el-col>
				</el-row>
				<el-row :gutter="16">
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">蒸汽煤耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<div style="margin-bottom:-50px">
							<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(kg/t)</h4>
							<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">103.72</h1>
						</div>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<mechart id="echart31" title="" subtitle="" :style="'height:160px'" :options="echart31_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">蒸汽电耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(kwh/t)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">24.75</h1>
							</div>
							<mechart id="echart32" title="" subtitle="" :style="'height:160px'" :options="echart32_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">新水电耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(kwh/t)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">0.56</h1>
							</div>
							<mechart id="echart33" title="" subtitle="" :style="'height:160px'" :options="echart33_opts" />
						</el-card>
					</el-col>
					<el-col :span="6">
						<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px ">环水电耗趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
						<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
							<div style="margin-bottom:-50px">
								<h4 style="color:#ffffffaa;font-size:12px;text-align:right;margin:0;margin-bottom:5px;">全年累计(kwh/t)</h4>
								<h1 style="color:#fff;text-align:right;margin:0;font-size:28px;">0.248</h1>
							</div>
							<mechart id="echart34" title="" subtitle="" :style="'height:160px'" :options="echart34_opts" />
						</el-card>
					</el-col>
				</el-row>
			</el-main>
		</el-container>
	</div>
</template>
<script>
	import request from "../../plugins/axios";
	import indexmap from "./page/index_map.vue";
	import mechart from "../../components/mechart.vue";
	import mechartpie from "../../components/mechart_pie.vue";
	export default {
		name: "App",
		data() {
			return {
				tab: 1,
				company_id: 1,
				// clientHeight: "", //页面高度
				role: window.localStorage.getItem("role"),
				nickname: window.localStorage.getItem("nickname"),
				head: window.localStorage.getItem("head"),
				echart0_opts: {
					//图例
					legend: {
						//图例
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "top", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "20",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "氧化铝综合能耗",
							type: "bar",
							// smooth: true,
							data: [344.19, 319.64, 315.55, 311.15, 309.57, 301.1, 298.05],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart1_opts: {
					//图例
					legend: {
						//图例
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "top", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "20",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "氧化铝工艺能耗",
							type: "bar",
							// smooth: true,
							data: [333.01, 307.17, 307.32, 303.85, 305.11, 295.38, 292.73],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#b9ffaa",
										},
										{
											offset: 1,
											color: "#00daf9",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart2_opts: {
					//图例
					legend: {
						//图例
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "top", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "20",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月"],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "烧结法",
							type: "bar",
							// smooth: true,
							data: [
								23825.64, 32188.71, 27599.97, 28061.23, 15607.5, 17218.48,
								20917.76,
							],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
						{
							name: "拜耳法",
							type: "bar",
							// smooth: true,
							data: [
								41764.08, 49507.39, 55796.96, 53624.5, 57228.79, 56023.73,
								58828.93,
							],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
						{
							name: "能源生产",
							type: "bar",
							// smooth: true,
							data: [
								22271.49, 19529.38, 19137.04, 20268.39, 20888.94, 20936.68,
								22424.98,
							],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
					],
				},
				echart11_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "电力",
							type: "bar",
							// smooth: true,
							data: [780.49, 698.74, 713.37, 677.28, 889.34, 758.88, 711.08],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#b9ffaa",
										},
										{
											offset: 1,
											color: "#00daf9",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart12_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "蒸汽",
							type: "bar",
							// smooth: true,
							data: [4.38, 3.94, 3.98, 3.43, 4.9, 3.5, 3.19],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#b9ffaa",
										},
										{
											offset: 1,
											color: "#00daf9",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart13_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "新水",
							type: "line",
							// smooth: true,
							data: [3.34, 3.1, 3.22, 3.51, 5.36, 3.62, 3.03],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#b9ffaa",
										},
										{
											offset: 1,
											color: "#00daf9",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart21_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "电力",
							type: "bar",
							// smooth: true,
							data: [263.45, 216.99, 229.57, 231.25, 204.15, 199.24, 201.35],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart22_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "蒸汽",
							type: "bar",
							// smooth: true,
							data: [2.63, 2.23, 2.13, 2.04, 1.97, 1.91, 1.85],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart23_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "新水",
							type: "line",
							stack: "能耗",
							// smooth: true,
							data: [1.26, 0.88, 0.82, 1, 0.69, 0.58, 0.55],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart24_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "天燃气",
							type: "bar",
							stack: "能耗",
							// smooth: true,
							data: [84.97, 78.46, 78.99, 86.11, 84.7, 84.48, 83.47],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart31_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "蒸汽煤耗",
							type: "bar",
							stack: "能耗",
							// smooth: true,
							data: [104.29, 104.15, 104.85, 103.43, 103.44, 103, 103],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart32_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "蒸汽电耗",
							type: "bar",
							stack: "能耗",
							// smooth: true,
							data: [25.81, 24.15, 24.98, 24.61, 24.39, 24.67, 24.72],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart33_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "新水电耗",
							type: "line",
							stack: "能耗",
							// smooth: true,
							data: [0.633, 0.553, 0.531, 0.604, 0.543, 0.523, 0.54],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart34_opts: {
					legend: {
						show: false,
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "环水电耗",
							type: "bar",
							stack: "能耗",
							// smooth: true,
							data: [0.239, 0.24, 0.251, 0.251, 0.238, 0.267, 0.247],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
			};
		},

		mounted() {
			// // 获取浏览器可视区域高度
			// this.clientHeight = `${document.documentElement.clientHeight}`; //document.body.clientWidth;
			// window.onresize = function temp() {
			// 	this.clientHeight = `${document.documentElement.clientHeight}`;
			// };
		},
		components: {
			indexmap,
			mechart,
			mechartpie,
		},
		methods: {},
	};
</script>